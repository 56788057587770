<!--  -->
<template>
  <div class="container" v-if="open=='01'">
    <img src="../../static/ic_success.png" alt="">
    <p>订票信息提交成功！</p>
  </div>
  <div class="container" v-else>
    <img src="../../static/ic_success.png" alt="">
    <p>退票成功！</p>
  </div>
</template>

<script>

export default {
    data () {
        return {
           open:'0'
        };
    },
    components: {},
    created() {
      this.open = this.$route.query.type;
    },
    methods: {
       
    },
    
}

</script>
<style scoped>
.container img{
    width: 1.92rem;
    height: 1.92rem;
    float: left;
    display: block;
    margin: 5.04rem 0 0 4.05rem;
}
.container p{
    width: 10rem;
    float: left;
    margin-top: 0.53rem;
    text-align: center;
    font-size: 0.373rem;
    color:#222222;
}

</style>